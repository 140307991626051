<template>
    <div class='security-overview-quote min-quote' v-if="component === 'Security Overview Chart'">
        <div class="qmod-quote">
            <span class='company-name'>{{name}}</span>
            <div class='qmod-segment'>
                <span class='qmod-last-currency'>${{getLastCurrency()}}</span>
                <span class='qmod-currency'>{{currencyType}}</span>
                <span :class="changeGroupClass">
                    <div class='arrow-container'>
                        <span class='arrow material-icons-outlined'>{{getArrow}}</span>
                    </div>
                    <span class='qmod-element qmod-change'>{{change}}</span>
                    <span class="qmod-element qmod-change-percent" >({{getChangePercent()}})</span>
                </span>
            </div>
        </div>
    </div>
    <div class="min-quote" v-else >
        <div :class='changeIconContainerClass' v-if='icon'  @touchstart="onQuoteTouched()">
            <span class='text' v-if="icon.type === 'text'" @click="onAssetClicked" :style="minQuoteStyle">
                {{icon.value}}
            </span>
            <span class='icon material-icons-outlined' v-else>
                {{icon.value}}
            </span>
        </div>
        <div class="qmod-quote">
            <div class='name-block'>
                <span class="display-name">{{name}}</span>
           </div>
           <span :class="changeGroupClass">
                <div class='arrow-container'>
                    <span class='arrow material-icons-outlined'>{{getArrow}}</span>
                </div>
                <span class="qmod-segment">
                    <span class="qmod-element qmod-change-percent" >{{getChangePercent()}}</span>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import numeral from 'numeral';
import ResearchService from '../../service/ResearchService';
/**
 * This version doesn't use a qmod, it expects the data to be passed as parameters as a result of our own async calls to the getQuotes api call
 */
export default {
    name: 'MiniQuotesSimple',
    emits: ['quote-touched'],
    props: {
        component: {
            type: String
        },

        lastCurrency: {
            type: Number,
        },
        currencyType: {
            type: String
        },

        name: {
            type: String,
            required: true
        },
        
        change: {
            type: Number,
            required: true,
        },
        changepercent: {
            type: Number,
            required: true,
        },

        icon: {
            type: Object
        }
      
    },

    data() {
        return {
           
         };
    },
    methods: {

        onQuoteTouched() {
            this.$emit('quote-touched');
        },

        onAssetClicked() {
            
            if( this.component == 'Popular Assets') {
                console.log( this.icon.value + "clicked");
                ResearchService.setSelectedSymbolFromQM(this.icon.value, (resp) => {
                    if (resp == 'success') {
                        this.$router.push({ path: '/equity' });
                    }
                });
            }
        },
        getChangePercent() {
            return numeral(Math.abs(this.changepercent)).format('0.00')+'%';
        },
        getLastCurrency() {
            return numeral(this.lastCurrency).format('0.00');
        },

        isChangePercentageUnderZero() {
            return this.change < 0;
        }
    },
   

    computed: {
        changeIconContainerClass() {
            return this.isChangePercentageUnderZero() ? "icon-container ch-down" : "icon-container ch-up";
        },
        changeGroupClass() {
            return this.isChangePercentageUnderZero() ? "qmod-change-group qmod-ch-down": "qmod-change-group qmod-ch-up"; 
        },

        getArrow() {
            return this.isChangePercentageUnderZero() ? "arrow_downward" : "arrow_upward"
        },

        minQuoteStyle() {
            if( this.component == 'Popular Assets') {
                return 'cursor: pointer;';
            }
            else {
                return '';
            }
        }


    },



    activated() {
        //console.debug(this.$options.name + ' activated');
    },
    deactivated() {
        //console.debug('mini qutotes simple deactived');
    },
    unmounted() {
        //console.debug('miniquotes simple unmounted');
    },
};
</script>

<style scoped>
::v-deep(*:not(.pi, .material-icons-outlined)), ::v-deep(.p-component){
    font-family: 'Trebuchet MS', 'Verdana';
}
.min-quote {
    display: flex;
    align-items: center;
}

.min-quote .qmod-quote {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
}
.min-quote .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #FFFFFF;
}
.min-quote .icon-container.ch-up {
    background: #33CC99;
}
.min-quote .icon-container.ch-down {
    background: #e63e3e;
}
.min-quote .qmod-quote .display-name {
    font-weight: bold;
    color: #32364e;
}

.min-quote .qmod-quote .qmod-change-group {
    display: flex;
    margin-left: auto;
    align-items: center;
}
.min-quote .qmod-quote .qmod-change-group.qmod-ch-up {
    color: #33cc99;
}
.min-quote .qmod-quote .qmod-change-group.qmod-ch-down {
    color: #e63e3e;
}
.min-quote .qmod-quote .qmod-change-group .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    padding: 4px;
    border-radius: 50%;
    margin-right: 8px;
}
.min-quote .qmod-quote .qmod-change-group .arrow-container .arrow {
    color: #FFFFFF;
    font-size: 16px;
}
.min-quote .qmod-quote .qmod-change-group.qmod-ch-down .arrow-container {
    background: #e63e3e;
}
.min-quote .qmod-quote .qmod-change-group.qmod-ch-up .arrow-container {
    background: #33cc99;
}

/* SECURITY OVERVIEW CHART STYLINGS */
.security-overview-quote .qmod-quote {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.security-overview-quote .qmod-quote .qmod-change-group {
    margin-left: 0px;
}

.security-overview-quote .qmod-quote .qmod-segment {
    display: flex;
}
</style>