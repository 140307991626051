<template>
    <div>
        <LoadingSpinner :showLoading="!showScreener" style="margin-top: -10px;width: 100%"/>
        <div id="ms" v-show="showScreener">
            <div class="qmodii" id="qmodii-div">
               <div id="marketScreenerContainer"></div>
            </div>
        </div>

        <AttributionFooter authors='quoteMedia' v-if='showScreener'/>

        <AddToListModal :items="selectedRows" ref='addToListModal' @addResultsToList="onSave" />
    </div>
</template>

<script>
import { QM_ENV, QM_WM_ID/*, QMODII_VERSION*/ } from '@/common/config';
import ResearchService from '../../service/ResearchService';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import AttributionFooter from '../footer/AttributionFooter.vue';
import AddToListModal from '../modal/assetLists/AddToListModal.vue';


export default {
    name: 'MarketScreener',

    computed: {
        saveToButtonDisabled() {
            let searchTextInput = document.getElementById('screenerListSearchBar');
            if( searchTextInput ) {
                return !this.selectedList && searchTextInput.value.trim() == '';
            }
            else {
                return !this.selectedList;
            }
        },

       

    },
    components: {AttributionFooter, LoadingSpinner, AddToListModal},
    mounted() {
        console.log("mounting MarketScreener")
        // let d = document.getElementById('MarketScreener');
        // if( d ){
        //     d.remove();
        // }
        if( this.observer ) {
            console.log("on mount observer existed - disconnecting")
            this.observer.disconnect();
        }
        this.observer = new MutationObserver((mutations) => {this.onMutations(mutations)});
            
        this.observer.observe( document.getElementById("ms"), { childList: true, subtree: true });
        
         this.buildScreener();
       
        
        

        this.justMounted = true;

        

      

    },
    unmounted() {
        console.log("MarketScreener unmounted");
        this.observer.disconnect();
        this.clearSelections();
        this.showScreener = false;

        // const qmodii = document.getElementById('qmodiiLoader')
        // if (qmodii) {
        //     qmodii.remove()
        //     window.qModii = null;
        // }

        // let d = document.getElementById('marketScreenerContainer');
        // if( d ){
        //     d.remove();
        // }
    },

    activated() {
        console.debug(this.$options.name + ' activated');
       
       
            
       
       
       
    },
    deactivated() {
        console.debug(this.$options.name + ' deactivated');
        this.justMounted = false;
        // this.showScreener = false;
    },

    data() {
        return {
            justMounted: false,
            lang: 'en',
            
            adjusted: false,
            showScreener: false,
            saveListButton: null,
            selectedRows: [],

            displayCreateSaveToModal: false,
            filteredLists: [],
            selectedList : null,
            createNewList : false,
            newListName :'',
            checkboxHeader: null,

        };


    },

    watch: {
        selectedRows :{
            handler(val, oldVal) { //eslint-disable-line
            //    console.log("in row watcher with selectedRows " + this.selectedRows.length)
                if( this.selectedRows.length > 0 ) {
                    if( this.saveListButton) {
                        this.saveListButton.classList.remove("disabled-save-list-button")
                    }
                }
                else {
                    if( this.saveListButton && !this.saveListButton.classList.contains("disabled-save-list-button")) {
                        this.saveListButton.classList.add("disabled-save-list-button")
                    }
                }
            },
            deep: true
        },
    },

    methods: {

        isEtfSelected() {
            try {
                let assetTypeElement = document.getElementsByClassName("v-select__selection v-select__selection--comma");
                if( assetTypeElement){
                    let assetType = assetTypeElement[0].childNodes[0];
                    //console.log("selected asset type is", assetType.textContent)
                    if( assetType.textContent.trim().toLowerCase() == 'etfs'){
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }catch(err){
                console.log("error attempting determine selected asset type", err);
                return false;
            }
        },

        onSave(value) {
			this.selectedList = value;

			this.saveToList();
        },

        saveToList() {
            if( !this.selectedList.name || this.selectedList.name === undefined) {
                
                console.log("saving to new list called " + this.selectedList);
                ResearchService.saveScreenerResultsToNewList(this.selectedList, this.selectedRows).then(resp => {
                    if( resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: "Results saved to list " +this.selectedList, detail: '', life: 3000, group: 'tc' });
                        this.$refs.addToListModal.close();
                        this.clearSelections();
                    }
                    else {
                        console.log("error saving results to new list "+ resp.data.message);
                        this.$toast.add({ severity: 'error', summary: "Error saving results to list." +this.selectedList, detail: 'Please try again, and let us know if the problem persists.', life: 3000, group: 'tc' });
                        this.$refs.addToListModal.close();
                    }
                    this.selectedRows.length = 0;
                })
                .catch(error => {
                    console.log("error saving results to new list "+ error);
                    this.$toast.add({ severity: 'error', summary: "Error saving results to list." +this.selectedList, detail: 'Please try again, and let us know if the problem persists.', life: 3000, group: 'tc' });
                    this.$refs.addToListModal.close();
                    this.selectedRows.length = 0;
                });
            }
            else {
                console.log("saving to existing list called " + this.selectedList.name);
                ResearchService.saveScreenerResultsToList(this.selectedList.assetListId, this.selectedRows).then(resp => {
                    if( resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: "Results saved to list " +this.selectedList.name, detail: '', life: 3000, group: 'tc' });
                        this.$refs.addToListModal.close();
                        this.clearSelections();

                    }
                    else {
                        console.log("error saving results to list "+ resp.data.message);
                        this.$toast.add({ severity: 'error', summary: "Error saving results to list." +this.selectedList.name, detail: 'Please try again, and let us know if the problem persists.', life: 3000, group: 'tc' });
                        this.$refs.addToListModal.close();
                    }
                    this.selectedRows.length = 0;
                })
                .catch(error => {
                    console.log("error saving results to list "+ error);
                    this.$toast.add({ severity: 'error', summary: "Error saving results to list." +this.selectedList.name, detail: 'Please try again, and let us know if the problem persists.', life: 3000, group: 'tc' });
                    this.$refs.addToListModal.close();
                    this.selectedRows.length = 0;
                });
            }
        },
        searchLists(event) {
			ResearchService.getAssetLists(10, 0, event.query).then((resp) => {
				if( resp.data.status == "success"){
					this.filteredLists  = resp.data.lists;
					
				}
			});
		},

        clearListInput(e) {
			e['query'] = '';
            this.$refs.screenerListSearchBar.$emit('complete', e)
			this.$refs.screenerListSearchBar.show();
        },

        buildScreener() {

        //     let tmplEle = document.createElement('div');
        //     tmplEle.setAttribute("id", "marketScreenerContainer");
        //      document.getElementById('qmodii-div').appendChild(tmplEle);

        //   let qmodii = this.createQmodNode("qmodiiLoader", "//static.c1.quotemedia.com/qmod/qmodii/v1.38.0/qModii.umd.min.js", this.$store.state.sid);  
        //   document.body.appendChild(qmodii);

          this.initQmodii(5000).then( () => {
              window.qModii.init(QM_WM_ID, '#marketScreenerContainer', 'MarketScreener', {}, this.lang, QM_ENV, this.$store.state.sid); //eslint-disable-line
              
              setTimeout(() => {
                    this.showScreener = true;
                }, 2000);
              
           });

            
        },

        // createQmodNode(id, src, sid) {
        //     let qmod = document.createElement('script');
        //     qmod.setAttribute("id", id);
        //     qmod.setAttribute("type", "application/javascript");
        //     qmod.setAttribute("src", src)
        //     qmod.setAttribute("data-qmod-wmid", QM_WM_ID);
        //     qmod.setAttribute("data-qmod-env", QM_ENV);
        //     qmod.setAttribute("async", "");
        //     if( id == 'qmodiiLoader') {
        //     qmod.setAttribute("data-qmod-version", QMODII_VERSION);
        //     }
        
        //     qmod.setAttribute("data-qmod-sid", sid);
        //     return qmod;
        // },

        /** Waits until the window.qModii object exists and then calls init on it */
        initQmodii(timeout) {
        
            var start = Date.now();
            return new Promise(waitForQmodii); 
        
            function waitForQmodii(resolve, reject) {
                if (window.qModii)  { 
                    console.debug("screener qmodii initialized.");
                    resolve(window.qModii);
                }else if (timeout && (Date.now() - start) >= timeout){
                        reject(new Error("timeout waiting for qmodii"));
                }else{
                        setTimeout(waitForQmodii.bind(this, resolve, reject), 30);
                }
            }
        },

        /******** These methods manipulate the dom to add our even handlers and custom css classes ********/
        onMutations(mutations){
            //console.debug("onMutations");
            mutations.forEach( (mutation) => {this.onMutation(mutation)} );
        },
        onMutation(mutation) {
            //console.debug("on A Mutation ");
            mutation.addedNodes.forEach(node=> {
                //console.log("in added nodes "+node.nodeName + " "+node.className )
                if( node.className == 'qmod-screener-results') {
                     this.showScreener = true;
                      console.log('handleResultsDivAdded and classsName =  qmod-screener-results')
                    this.handleResultsDivAdded(node)
                }
                else if( node.className == 'qmod-screener-results-table'){
                    console.log("new results table added");
                    this.handleResultsTableAdded(node);
                }
                else {
                    if( node.nodeName == "TR" && node.parentNode.classList.contains('qmod-screener-results-table')){
                        console.log(node.nodeName +" new results row added");
                        this.handleResultsRowAdded(node);
                    }
                    else if( node.nodeName == 'DIV' && node.classList.contains("qmod-MarketScreener") ) {
                        // this.showScreener = true;
                        console.log('handleResultsDivAdded and classlist contains qmod-MarketScreener')
                        this.handleResultsDivAdded(this.findFirstChildByClass(node,'qmod-screener-results'));
                    }
                }
            })
          
        },

        findFirstChildByClass(element, className) {
            var foundElement = null, found; // eslint-disable-line
            function recurse(element, className, found) {
                for (var i = 0; i < element.childNodes.length && !found; i++) {
                    var el = element.childNodes[i];
                    var classes = el.className != undefined? el.className.split(" ") : [];
                    for (var j = 0, jl = classes.length; j < jl; j++) {
                        if (classes[j] == className) {
                            found = true;
                            foundElement = element.childNodes[i];
                            break;
                        }
                    }
                    if(found)
                        break;
                    recurse(element.childNodes[i], className, found);
                }
            }
            recurse(element, className, false);
            return foundElement;
        },

        handleResultsDivAdded(div) {
            if( div ) {
                let tables = div.getElementsByClassName("qmod-screener-results-table")
                //tables.forEach(table => {
                for( const table of tables){
                    this.handleResultsTableAdded(table);
                }
                //);
                this.addSaveSelectedButton(div, tables);
                
            }

           
            
        },


        addSaveSelectedButton(div, tables) {//eslint-disable-line
            let saveFunction = () => {
                console.log("would have saved " + this.selectedRows.length + " equities");
                this.createNewList = false;
                this.selectedList = null;
                this.$refs.addToListModal.open();
                            
            }

            let saveListTab = this.findFirstChildByClass(div, 'save-list-tab') ;
            if(!saveListTab ) {
                let exportTab = this.findFirstChildByClass(div, 'qmod-export-tab');
                if( exportTab) {
                    saveListTab = document.createElement('div');
                    saveListTab.setAttribute("class", "v-tabs__div save-list-tab");
                    saveListTab.innerHTML = 
                    `<button type="button" class="v-btn v-btn--small theme--light primary disabled-save-list-button"><div class="v-btn__content">Save Selected</div></button>`;
                    saveListTab.onclick = function(saveListTab) {
                           return function() {
                               
                                if( !saveListTab.children[0].classList.contains("disabled-save-list-button")) {
                                
                                    saveFunction();
                                     
                                }
                        
                            
                            };}(saveListTab, this.selectedRows);
                    
                    exportTab.parentNode.appendChild(saveListTab)
                }
                
                
            }
            this.saveListButton = saveListTab.children[0];
        
        },

        handleResultsTableAdded(table){
           //console.debug("handle table added");
            for( var i = 0; i< table.children.length; ++i ) {
                 let tr  = table.children[i];
                if ( i == 0 ) {
                    this.handleResultsRowHeaderAdded(tr)
                }
                else {
                    this.handleResultsRowAdded(tr)
                }
               
                
               
               
            }     
        },
        handleResultsRowHeaderAdded(tr) {
            
            let td = tr.children[0];
           
                let checkboxCol = document.createElement("TH");
                checkboxCol.style="width: 2px;"
                 if( !this.isEtfSelected() ){
                    checkboxCol.innerHTML = 
                        `<div class="v-input--selection-controls__input" style="width:5px;">
                            <input aria-checked="false" role="checkbox" type="checkbox" >
                            <div class="v-input--selection-controls__ripple"></div>
                            <i aria-hidden="true" class="v-icon material-icons theme--light" style="font-size:16px;">check_box_outline_blank</i>
                            
                        </div>`
                 }
                 else {
                    checkboxCol.innerHTML = 
                        `<div class="v-input--selection-controls__input" style="width:5px;pointer-events:none;opacity:0.3;">
                            <input aria-checked="false" role="checkbox" type="checkbox" disabled="disabled">
                            <div class="v-input--selection-controls__ripple"></div>
                            <i aria-hidden="true" class="v-icon material-icons theme--light" style="font-size:16px;">check_box_outline_blank</i>
                            
                        </div>`
                 }
                
                
                tr.prepend(checkboxCol);
                td.classList.add("screener-result-header");
                this.checkboxHeader = checkboxCol;


                if( !this.isEtfSelected() ){
                    checkboxCol.onclick =  function ( checkboxCol, selectedRows) {//eslint-disable-line
                        
                        return function() {
                            let checkBoxIcon = checkboxCol.children[0].children[2];
                        // console.log("checkBoxIcon.innerHTML =" +checkBoxIcon.innerHTML)
                        let unchecking = false;
                            if( checkBoxIcon.innerHTML == "check_box_outline_blank") {
                                //console.log("setting to check_box")
                                checkBoxIcon.innerHTML = "check_box";
                            }else {
                                checkBoxIcon.innerHTML = "check_box_outline_blank";
                                unchecking = true;
                            }
                            selectedRows.length = 0;
                            let rows = this.parentNode.parentNode.children;
                            
                            for( var i = 1; i < rows.length; ++i ){
                                
                                let row = rows[i];
                                let symbol = '';
                                let company = '';
                                let symbolCol = row.children[1];
                                if( symbolCol.children.length > 0 ) {
                                    symbol = row.children[1].children[0].innerHTML
                                //     company = row.children[2].innerHTML;
                                }
                                else {
                                    symbol = row.children[1].innerHTML;
                                    // no company 
                                }
                            
                                let chkB = row.children[0].children[0].children[2];
                                if(unchecking) {
                                    if( row.classList.contains("selected-screener-row") ) {
                                        row.classList.remove("selected-screener-row")
                                        chkB.innerHTML = "check_box_outline_blank";
                                        chkB.setAttribute('style', 'font-size:16px;color:inherit;');
                                    }
                                
                                
                                }else {
                                    if( !row.classList.contains("selected-screener-row") ) {
                                        row.classList.add("selected-screener-row");
                                        chkB.innerHTML = "check_box";
                                        
                                    }
                                    selectedRows.push({symbol:symbol, name: company});

                                }
                            
                                
                            }
                            
                            //console.log("selectedRows now "+selectedRows.length )
                        
                        };
                        
                        
                    }(checkboxCol,this.selectedRows);
                }
            
        },

        clearSelections() {
            // if( this.checkboxHeader ) {
            //     console.log("clearning checkbox header")
            //     let checkBoxIcon = this.checkboxHeader.children[0].children[2];
            //     checkBoxIcon.innerHTML = "check_box_outline_blank";

                
            // }
                        
            this.selectedRows.length = 0;

            let tables = document.getElementsByClassName("qmod-screener-results-table")
            
            if( tables ) {
                for( const table of tables){
                    this.clearHeader(table);
                    this.clearSelectedRows(table);
                }
            }


            
        },

        clearHeader(table){
            if( table.children && table.children[0]) {
                let headerRow =  table.children[0];
                let checkBoxIcon = headerRow.children[0].children[0].children[2];
                 
               if( checkBoxIcon.innerHTML == "check_box") {
                    //console.log("setting to check_box")
                    checkBoxIcon.innerHTML = "check_box_outline_blank";
                }
            }
        },

        clearSelectedRows(table) {
            let rows =  table.children;
            console.log("rows length = "+ rows.length)
            for( var i = 1; i < rows.length; ++i ){
                
                let row = rows[i];
               
               
                
                let chkB = row.children[0].children[0].children[2];
                //console.log(chkB.outerHTML);
                if( row.classList.contains("selected-screener-row") ) {
                    row.classList.remove("selected-screener-row")
                    chkB.innerHTML = "check_box_outline_blank";
                    chkB.setAttribute('style', 'font-size:16px;color:inherit;');
                }
               // console.log(chkB.outerHTML);
                
                
            }
        },

        
       
        handleResultsRowAdded(tr) {
            let screenerVue = this;
            function removeSymbol(selectedRows, sym) {
                //console.log("selectedRows = "+ JSON.stringify(selectedRows));

                var index = -1;
                //selectedRows.indexOf(sym);
                for( var i = 0; i < selectedRows.length; ++i ) {
                    if( selectedRows[i].symbol === sym) {
                        index = i;
                        break;
                    }
                }
                if (index > -1) {
                    selectedRows.splice(index, 1);
                }
                
            }

            if( !tr.classList.contains("qmodii-child-table-tr") ) {
           
                let checkboxCol = document.createElement("TD");
                 if( !this.isEtfSelected() ){
                    checkboxCol.innerHTML = 
                        `<div class="v-input--selection-controls__input" style="width:5px;">
                            <input aria-checked="false" role="checkbox" type="checkbox" >
                            <div class="v-input--selection-controls__ripple"></div>
                            <i aria-hidden="true" class="v-icon material-icons theme--light" style="font-size:16px;color:inherit;">check_box_outline_blank</i>
                            
                        </div>`
                 }
                 else {
                    checkboxCol.innerHTML = 
                        `<div class="v-input--selection-controls__input" style="width:5px;pointer-events:none;opacity:0.3;">
                            <input aria-checked="false" role="checkbox" type="checkbox" disabled="disabled">
                            <div class="v-input--selection-controls__ripple"></div>
                            <i aria-hidden="true" class="v-icon material-icons theme--light" style="font-size:16px;color:inherit;">check_box_outline_blank</i>
                            
                        </div>`
                 }
                tr.prepend(checkboxCol);
            
                tr.classList.add("screener-row");

                let symbolTD =  tr.children[1];//(this.isEtf ? tr.children[1]: tr.children[0]);
                let clickHandler =  function(tr, checkboxCol, symbolTD, selectedRows) {
                        return function() {
                            if( !screenerVue.isEtfSelected() ){
                                let checkBoxIcon = checkboxCol.children[0].children[2];
                                if( checkBoxIcon.innerHTML == "check_box_outline_blank") {
                                    //console.log("setting to check_box")
                                    checkBoxIcon.innerHTML = "check_box";
                                    checkBoxIcon.style = "font-size:16px;color:#fff;";
                                }else {
                                    checkBoxIcon.innerHTML = "check_box_outline_blank";
                                    checkBoxIcon.style = "font-size:16px;color:inherit;";
                                }
                                let symbol = '';
                                let noCompany = false;
                                if( symbolTD.children.length > 0 ) {
                                    symbol = symbolTD.children[0].innerHTML;
                                }
                                else {
                                    symbol = symbolTD.innerHTML;
                                    noCompany = true;
                                }
                                if( tr.classList.contains("selected-screener-row") ) {
                                    tr.classList.remove("selected-screener-row")
                                    //console.log("will remove " + symbol);
                                    
                                    removeSymbol(selectedRows, symbol);

                                }
                                else {
                                    tr.classList.add("selected-screener-row");
                                    //console.log("will add " + symbol);
                                    let company = '';
                                    if( !noCompany) {
                                        company = tr.children[1].children[0].innerHTML;
                                    }
                                    selectedRows.push({symbol:symbol, name: company});
                                }
                            }

                        };
                    }(tr,checkboxCol, symbolTD, this.selectedRows);
                //checkboxCol.onclick = clickHandler;
                if( !this.isEtfSelected()){
                    tr.onclick = clickHandler;
                }
            
                symbolTD.classList.add("screener-row-symbol");
          
            
                /*let sym = td.innerHTML;
                td.innerHTML = '<a href="/index.html#/equity?sym='+sym+'">'+sym +"</a>"
                */
                let router = this.$router;
                
                symbolTD.onclick =  function() {
                        return function(e) {
                            e.preventDefault();
                            e.stopPropagation();
                            
                            let symbol = '';
                            if( this.children.length > 0 ) {
                                symbol = this.children[0].innerHTML;
                            }
                            else {
                                symbol = this.innerHTML;
                            }
                        
                            if( screenerVue.isEtfSelected() ){
                                //console.log(symbol +" eft clicked");
                                ResearchService.setSelectedFundSymbolFromQM(symbol, (resp) => {
                                    if (resp == 'success') {
                                        //console.log("sending to fund overview")
                                        router.push({ path: '/fund' });
                                    }
                                    else {
                                        console.log("err on click of fund", resp)
                                    }
                                });
                            }
                            else {
                                console.log(symbol +" equity clicked");
                                ResearchService.setSelectedSymbolFromQM(symbol, (resp) => {
                                    if (resp == 'success') {
                                        router.push({ path: '/equity' });
                                    }
                                });
                            }
                        };
                        
                    }(symbolTD);
            }
            
        },

       
        
       

      
    },
};
</script>

<style scoped>
.more-options {
    position: absolute;
    right: 0.75vw;
}

@media only screen and (max-width: 800px) {
    ::v-deep(.qmodii .qmod-selectedcriteria .qmod-selectedcriteria-item span.qmod-table-extras) {
        flex-flow: wrap;
        margin-left: 0px;
    }
}

::v-deep(.qmodii .qmod-table-totalElements) {
    display: block;
}
::v-deep(.qmodii .qmod-ul .qmod-criteria-li.qmod-active-li,
.qmodii .qmod-ul .qmod-criteria-li:hover) {
    background-color: #32364E !important;
    color: #fff;
}
::v-deep(.qmodii .primary ){
    background-color: #32364E !important;
    border-color: #32364E !important;
}
::v-deep(.qmodii .accent--text) {
    color: #1b0e0d !important;
    caret-color: #82b1ff !important;
}

::v-deep(.qmodii .qmod-ul .qmod-criteria-li:hover) {
    background-color: #32364E;
    color: #fff;
}

::v-deep(.qmodii .qmod-ul .qmod-criteria-li:hover .theme--light.v-label ) {
    background-color: #32364E;
    color: #fff;
}
/* ::v-deep(.qmodii .theme--light.v-input:not(.v-input--is-disabled) input) {
    color: #32364E;
} */
::v-deep(.qmodii .qmod-ul .qmod-criteria-li:hover .theme--light.theme--light.v-icon ) {
    color: #fff !important;
}



::v-deep(.qmodii .theme--light.v-icon) {
    color: #32364E;
}

::v-deep(.qmodii .theme--light.v-label) {
    color: #32364E;
}

::v-deep(.accent) {
    background-color: #32364E !important;
    border-color: #32364E !important;
}

::v-deep(.qmodii .qmod-criter-collpase .v-btn__content){
     color: #32364E;

}

::v-deep(.qmodii .qmod-side-btns .v-btn__content) {
    color: #32364E;
}
::v-deep(.qmodii .qmod-side-btns .primary .v-btn__content) {
    color: #fff;
}

::v-deep(.primary--text){
    color: #32364E !important;
}

::v-deep(.qmodii .blue--text.text--darken-1){
    color: #32364E !important;
}

::v-deep(.qmodii .highcharts-dense-data .highcharts-tracker) {
    fill: #32364E;
}

::v-deep( svg > g.highcharts-series-group > g.highcharts-series.highcharts-series-0.highcharts-column-series.highcharts-color-0.highcharts-dense-data.highcharts-tracker > rect) {
    fill: #32364E !important;
}

::v-deep(.qmodii .noUi-handle:before){
    background: #32364E;

    background-color: #32364E; 
}
::v-deep(.qmodii .noUi-handle:after ){
    background: #32364E;

    background-color: #32364E;
}

::v-deep(.qmodii .qmod-component-header) {
    display:none;
}

::v-deep(.qmodii .theme--light.application){
    background: transparent;
}

::v-deep(.qmod-export-tab) {
    display:none !important;
    pointer-events: none;
}

::v-deep(.save-list-tab) {
    margin-left: auto;
    margin-right: 12px;
    cursor: pointer;
}

::v-deep(.selected-screener-row ) {
    background-color: #32364E;
    color: #fff;
}

::v-deep(.screener-result-header) {
     cursor: pointer;
}


::v-deep(.qmodii .screener-row:hover) {
    background: #32364E !important;
    color: #fff;
}

::v-deep(.screener-row-symbol ) {
    cursor: pointer;
    color: #33CC99;
}

::v-deep(.qmodii .theme--light.application) {
    background: #fff !important;
}

::v-deep(.disabled-save-list-button) {
    pointer-events: none;
    opacity: 0.2;
}

::v-deep(.qmodii .v-overlay--active:before) {
    opacity:0;
}

/** hide paging until working */
/* ::v-deep(.qmodii .qmod-selectedcriteria .qmod-selectedcriteria-item.qmod-selectedcriteria-btns .text-xs-center) {
    display:none;
    pointer-events: none;
}

::v-deep(.qmodii .qmod-selectedcriteria .qmod-selectedcriteria-item.qmod-selectedcriteria-btns  .qmod-table-totalElements){
     display:none;
} */
.list-modal .p-dialog-content > input{
	width:25rem;
	margin-left: 5px;
	margin-right:5px;
}

.list-modal .p-dialog-footer .p-button {
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	font-weight: 600;
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .list-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 1rem;
 }

 .list-modal .p-dialog-footer .accept {
	border-color: #33cc99;
 }

 .list-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33cc99;
	border: 2px solid #33cc99;
 }

 .list-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
	border: 2px solid #32364E;
 } 


 
::v-deep(.new-list-button) {
    border-top-left-radius:0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 1rem;
}



::v-deep(.p-autocomplete-input.p-inputtext.p-component){
	width:21rem;
	border-radius: 8px;
}
::v-deep(.p-inputtext:enabled:hover) {
	border-color: #33CC99;
}
::v-deep(.p-inputtext:enabled:focus) {
	border-color: #33CC99;
	box-shadow: none;
}

::v-deep(#screenerListSearchBar) {
	width: 30rem;
    /* border-top-right-radius: 0;
    border-bottom-right-radius: 0; */
}


::v-deep(.qmodii .qmod-table) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}
::v-deep(.qmodii .qmod-criteria-pick) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}
::v-deep(.qmodii .v-label) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}
::v-deep(.qmodii .v-input) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}

::v-deep(.qmodii .qmod-table tr td, .qmodii .qmod-table tr th) {
    padding-top: 5px;
    padding-bottom: 5px;
}
</style>

<style >



.list-modal {
	box-shadow: none;
}

.list-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
}

.list-modal .p-dialog-header .p-dialog-title {
	font-family: "Trebuchet MS", "Verdana";
	font-weight: 600;
}

.list-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 0;
}

.list-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
    text-align: center;
}

</style>