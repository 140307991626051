<template>
    <div style="position: relative">
        <h2 class="heading-title">Market Indices</h2>
        <LoadingSpinner :showLoading="!chartInitialized" />
        <MoreOptionsMenu class="more-options" @click="toggleMenu" v-show="chartInitialized" />
        <Menu ref="menu" :model="moreMenuItems" :popup="true" />
        <div id="miniMarketIndices" data-qmod-tool="minimarketindices" :data-qmod-params="miniMarketIndicesParams" class="minimarketindices-qtool" ref="miniMarketIndices" />
        <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';

export default {
    name: 'MiniMarketIndices',

    computed: {
        miniMarketIndicesParams() {
            return JSON.stringify({
                lang: 'en',
                chartParams: {
                    chfill: 'eeeeee',
                    chfill2: '94E4C9',
                    chln: '33CC99',
                    chscale: '1m',
                    chcon: 'on',
                    chfrmon: 'off',
                    chtype: 'Mountain',
                    chbgch: 'ffffff',
                    chgrd: 'e7e7e7',
                    chbdr: 'e7e7e7',
                    chxyc: '666666',
                    chdon: 'off',
                    chton: 'off',
                    chwid: '300',
                    chhig: '200',
                },
                // clickAction: 'top',
                // clickTarget: '_self',
                useLongName: true,
                tooltip: true,
            });
        },
    },
    components: {
        LoadingSpinner,
        MoreOptionsMenu,
        SnapshotDialog,
    },
    mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute('type', 'text/template');

        let txt = document.createTextNode(
            `
<div class="qmod-ui-tool qmod-minimarketindices qmod-mini-tool">
    <div class="detailed-quote-modal">
        <div class="qmod-ui-modal" rv-show="modal.show">
            <div class="qmod-ui-modal-titlebar">
                <div class="qmod-ui-modal-title">{data.modalObj.equityinfo.longname} ( {data.modalObj.symbolstring} )</div>
                <div class="qmod-ui-titlebar-buttons"><button class="qmod-btn qmod-modal-close" rv-on-click="modal.close"><i class="fa fa-times"></i></button></div>
            </div>
            <div class="qmod-ui-modal-body">
                <div class="pure-g" rv-unless="data.modalObj.datatype | = 'n/a'">
                    <div class="pure-u-1">
                        <div class="qmod-chart">
                            <div class="qmod-chart-plot">
                                <img class="qmod-tab-img" onerror="this.classList.add('notfound')" rv-src="data.modalObj.qmURL"/>
                            </div>
                            <ul class="qmod-button-group">
                                <li rv-unless="data.modalObj.entitlement | or 'EOD' 'PD'"><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '1d'">1d</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '5d'">5d</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '1m'">1m</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '3m'">3m</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '6m'">6m</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '1y'">1y</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '2y'">2y</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '3y'">3y</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '4y'">4y</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '5y'">5y</button></li>
                                <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.modalObj.chscale | = '10y'">10y</button></li>
                            </ul>
                        </div>
                    </div>
                    <div class="pure-u-1">
                        <div class="qmod-quotegrid">
                            <div class="pure-g">
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.last">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Last</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.last | numeraljs"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.lasttradedatetime">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Last Trade</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr"><a class="qmod-tooltip" rv-title="data.modalObj.pricedata.lasttradedatetime | momentjs 'true' 'MMM DD, YYYY'" rv-on-mouseenter="data.qmtooltip" rv-text="data.modalObj.pricedata.lasttradedatetime | momentjs 'true' 'h:mm A'"></a></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Change</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.change | numeraljs '0.00' '0.00'"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Change&nbsp;%</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-html="data.modalObj.pricedata.changepercent | numeraljs '0.00' '0.00' | postFix '%'"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.open">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Open</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.open | numeraljs"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.prevclose">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Prev&nbsp;Close</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.prevclose | numeraljs"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.high">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">High</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.high | numeraljs"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.low">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Low</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.low | numeraljs"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.bid">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Bid</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.bid | numeraljs"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.ask">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Ask</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.ask | numeraljs"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.fundamental.week52low">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Year&nbsp;Low</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr"><a class="qmod-popup-link" rv-on-click="data.togglePopup" data-popuptype="week52low" rv-text="data.modalObj.fundamental.week52low.content | numeraljs"></a></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.fundamental.week52high">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Year&nbsp;High</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr"><a class="qmod-popup-link" rv-on-click="data.togglePopup" data-popuptype="week52low" rv-text="data.modalObj.fundamental.week52high.content | numeraljs"></a></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.pricedata.sharevolume">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Vol</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr" rv-text="data.modalObj.pricedata.sharevolume | numeraljs '0,0'"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-1-2" rv-if="data.modalObj.key.exchange">
                                    <div class="qmod-line-sep">
                                        <div class="pure-u-1-2">
                                            <div class="qmod-label qmod-textl">Exchange</div>
                                        </div>
                                        <div class="pure-u-1-2">
                                            <div class="qmod-data-point qmod-textr"><a class="qmod-tooltip" rv-title="data.modalObj.key.exLgName" rv-on-mouseenter="data.qmtooltip" rv-text="data.modalObj.key.exchange"></a></div>
                                        </div>
                                    </div>
                                </div>
                                <!--div.pure-u-1-2(rv-if="data.modalObj.fundamental.float")div.qmod-line-sep
                                    div.pure-u-1-2: div.qmod-label.qmod-textl Float
                                    div.pure-u-1-2: div.qmod-data-point.qmod-textr(rv-text="data.modalObj.fundamental.float | numeraljs")
                                    -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pure-u-1 qmod-textc" rv-if="data.modalObj.datatype | = 'n/a'">
                    <div class="qmod-label">No Data Exists for {data.modalObj.symbolstring}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="qmod-block-wrapper">
        <div class="pure-g">
            <div class="pure-u-1-1">
                <div rv-if="data.interactiveChart">
                    <div class="qmod-pricehistory-chart-con">
                        <div class="qmod-topChart-head">
                            <span>{data.topChart.longname}</span><span class="qmod-entitlement" rv-qmodent="data.topChart.entitlement" rv-unless="data.topChart.entitlement | = 'DL'">&nbsp; {data.topChart.entitlement}</span><span class="qmod-topChart-change" rv-qmodchange="data.topChart.change">{data.topChart.change | asQHLast }</span><span class="qmod-topChart-change fa" rv-qmodchange="data.topChart.change" rv-qmodchangeicon="data.topChart.change"></span>
                            <div class="phistory-chart"></div>
                            <div class="qmod-pricehistory-chart"></div>
                            <div class="qmod-chart-buttons-rs">
                                <ul class="qmod-legend">
                                    <li rv-each-time="data.binders.dateRange.list" rv-show="data.binders.dateRange.visible" rv-on-click="data.binders.dateRange.change">
                                        <button class="qmod-btn" rv-class-qmod-active="index|= data.binders.dateRange.selected">
                                            <div class="qmod-btn-text"> {time.label}</div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="qmod-topChart" rv-if="data.chartData" rv-hide="data.interactiveChart">
                    <div class="qmod-topChart-head"><span>{data.topChart.longname}</span><span class="qmod-entitlement" rv-qmodent="data.topChart.entitlement" rv-unless="data.topChart.entitlement | = 'DL'">&nbsp; {data.topChart.entitlement}</span><span class="qmod-topChart-change" rv-qmodchange="data.topChart.change">{data.topChart.change | asQHLast }</span><span class="qmod-topChart-change fa" rv-qmodchange="data.topChart.change" rv-qmodchangeicon="data.topChart.change"></span></div>
                    <div class="qmod-chart-plot"><img class="qmod-tab-img" onerror="this.classList.add('notfound')" rv-src="data.topChart.url"/></div>
                    <ul class="qmod-button-group">
                        <li rv-unless="data.topChart.entitlement | or 'EOD' 'PD'"><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '1d'">1d</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '5d'">5d</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '1m'">1m</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '3m'">3m</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '6m'">6m</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '1y'">1y</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '2y'">2y</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '3y'">3y</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '4y'">4y</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '5y'">5y</button></li>
                        <li><button class="qmod-btn" rv-on-click="data.changeChart" rv-class-qmod-btn-active="data.topChart.chscale | = '10y'">10y</button></li>
                    </ul>
                </div>
                <div class="qmod-tab-container">
                    <ul class="qmod-tab-list">
                        <li class="qmod-tab-item" rv-each-tab="indices" rv-class-qmod-active="index | = 0" rv-data-symbol="tab.symbolList">
                            <a class="qmod-tab-item-link" rv-on-click="data.changeTab">
                                <div class="qmod-tab-text">{tab.name}</div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="qmod-tab-container-bottom"></div>
                <div class="scrollable-table-wrap">
                    <div class="qmod-minimarketindices-table-con">
                        <div class="qmod-no-data" rv-if="data.noData">No data is available for the provided symbols.</div>
                        <table class="qmod-table qmod-minimarketindices-table responsive nowrap" width="100%" rv-unless="data.noData">
                            <thead>
                                <tr>
                                    <th class="qmod-textl qmod-th-1" style="width:40%">Index</th>
                                    <th class="qmod-textr qmod-th-2" style="width:20%">Last</th>
                                    <th class="qmod-textr qmod-th-3" style="width:20%">Chg</th>
                                    <th class="qmod-textr qmod-th-4" style="width:20%">%Chg</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="qmod-table-row" rv-each-tradee="data.data" rv-data-symbol="tradee.symbolstring" rv-on-click="data.clickRow" rv-hide="tradee.entitlement | = 'NA'" rv-class-qmod-tr-active="index | = 0">
                                    <td class="qmod-textl qmod-td-1" rv-unless="uln | = false" rv-on-click="data.clickLongName">
                                        <spav rv-if="tabdata.equityinfo.longname"><span class="qmod-tooltip" rv-title="tradee.equityinfo.longname" rv-on-mouseenter="data.qmtooltip" rv-html="tradee.equityinfo.longname"></span></spav>
                                        <spav rv-unless="tabdata.equityinfo.longname"><span class="qmod-tooltip" rv-title="tradee.equityinfo.shortname" rv-on-mouseenter="data.qmtooltip" rv-html="tradee.equityinfo.shortname"></span></spav>
                                        <span class="qmod-entitlement" rv-qmodent="tradee.entitlement" rv-unless="tradee.entitlement | = 'DL'">&nbsp; {tradee.entitlement}</span>
                                    </td>
                                    <td class="qmod-textl qmod-td-2" rv-unless="uln | = true" rv-on-click="data.clickSymbol"><span rv-title="tradee.equityinfo.longname" rv-on-mouseenter="data.qmtooltip" rv-html="tradee.symbolstring"></span><span class="qmod-entitlement" rv-qmodent="tradee.entitlement" rv-unless="tradee.entitlement | = 'DL'">&nbsp; {tradee.entitlement}</span></td>
                                    <td class="qmod-textr qmod-td-3" rv-data-sort="tradee.pricedata.last"><span>{tradee.pricedata.last | numeraljs '0,0.00'}</span></td>
                                    <td class="qmod-textr qmod-td-4" rv-data-sort="tradee.pricedata.change"><span rv-qmodchange="tradee.pricedata.change">{tradee.pricedata.change | numeraljs '0,0.00'}</span></td>
                                    <td class="qmod-textr qmod-td-5" rv-data-sort="tradee.pricedata.changepercent"><span rv-qmodchange="tradee.pricedata.change" rv-html="tradee.pricedata.changepercent | numeraljs '0.00' | postFix '%'"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <span class="qmod-mrktime qmod-textr"><span class="qmod-time" rv-html="data.data | getlatestdate_mmi | momentjs 'true' 'LLL' tLang"></span><span class="qmod-datetimezone">&nbsp;{data.timezone | asTimezone tLang}</span></span>
            </div>
        </div>
    </div>
    <div class="qmod-block-wrapper qmod-textc" rv-if="data.data.datatype | = 'n/a'">
        <h3 class="qmod-heading qmod-hrnd">&nbsp;</h3>
        <div class="pure-g">
            <div class="pure-u-1">
                <div class="qmod-label">No Data Exists for {data.symbolstring}</div>
            </div>
        </div>
    </div>
</div>
`
        );
        tmplEle.appendChild(txt);
        this.$refs.miniMarketIndices.appendChild(tmplEle);

        this.$refs.miniMarketIndices.addEventListener(
            'qmod-after-template-bind',
            () => {
                console.debug(this.$options.name + ' template bound**************');
                this.chartInitialized = true;
            },
            false
        ); //should ensure it is fired only once  {once:true}

        this.justMounted = true;
    },
    activated() {
        console.debug(this.$options.name + ' activated');
    },
    deactivated() {
        console.debug(this.$options.name + ' deactivated');
        this.justMounted = false;
        this.displaySnapshot = false;
    },

    data() {
        return {
            chartInitialized: false,
            justMounted: false,
            displaySnapshot: false,
            snapshotTarget: null,
            moreMenuItems: [
                {
                    label: 'Take Snapshot',

                    icon: 'pi pi-camera',
                    command: () => {
                        this.openSnapshotDialog();
                    },
                },
            ],
        };
    },

    methods: {
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = this.$refs.miniMarketIndices.getElementsByClassName('qmod-ui-tool')[0];
            this.displaySnapshot = true;
        },
    },
};
</script>
<style scoped>
::v-deep(img.qmod-tab-img.notfound) {  
   content: url("/invrs-icon-white1.png");
   width: 100px;
    height: 100px !important;
    opacity: 0.3;
    margin:auto;
}

</style>