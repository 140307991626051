<template>
    <div>
        <div class="tab-content" v-if='canAccessFeature'>
            <keep-alive>
                <DividendsCalendarVisuals v-observe-visibility="visibilityChanged" v-if="hasFeatureEnabled" />
            </keep-alive>
        </div>

        <UpgradeToPremiumModal ref="upgradeToPremiumModal" :bodyText="bodyText" @cancel-upgrade="cancelUpgrade" />
    </div>
</template>

<script>
import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';
import { mapState } from 'vuex';
import UserUtils from '../../utilities/UserUtils';

import DividendsCalendarVisuals from '../QuoteMedia/DividendsCalendarVisuals';
import UpgradeToPremiumModal from '../modal/UpgradeToPremiumModal';

export default {
    name: 'EarningsCalendar',
    emits: ['access-denied'],
    props: {
        hasFeatureEnabled: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            //CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

            justMounted: false,
            active: false,
            bodyText: 'To get full access to dividend calendars across exchanges upgrade to a Premium subscription.',
        };
    },

    components: {
        DividendsCalendarVisuals,
        UpgradeToPremiumModal,
    },

    computed: {
        ...mapState(['selectedEquity']),

        canAccessFeature() {
            return this.hasFeatureEnabled && UserUtils.isPremiumSubscriber();
        }
    },

    watch: {
        selectedEquity() {
            console.log(this.$options.name + ' selected equity watcher called');
            if (!this.justMounted) {
                if (this.$route.path == '/sandbox/dividendsCalendar') {
                    console.log(this.$options.name + ' about to call qmod loadtools');

                    // window.earningsCalendarQMod.loadTools();
                }
            }
        },
        $route(newRoute, oldRoute) { //eslint-disable-line
            if (newRoute.fullPath == '/sandbox/dividendsCalendar') {
                console.log(this.$options.name + ' route watcher');
              
                    if (this.canAccessFeature) {
                        // window.earningsCalendarQMod.loadTools();
                    } else {
                        this.$refs.upgradeToPremiumModal.open();
                    }
               
            }
        },
    },

    mounted() {
        console.log(this.$options.name + ' mounted');
        this.justMounted = true;

       
    },

    activated() {
        this.active = true;
        console.log(this.$options.name + ' activated');
        if (!this.justMounted) {
            // window.earningsCalendarQMod.loadTools();
        }
        this.justMounted = false;
    },

    deactivated() {
        console.log(this.$options.name + ' deactivated');
        this.justMounted = false;
        this.active = false;
    },

    methods: {
        visibilityChanged(isVisible, entry) { // eslint-disable-line
            if (isVisible) {
                // window.dispatchEvent(new Event('resize'));
            }
        },

        cancelUpgrade() {
            this.$emit('access-denied');
        },
    },
};
</script>