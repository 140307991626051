<template>
	<PillMenuBar :navigationItems="menuItems" headerType="main">

		<template #custom>
			<template v-if="$route.path == '/sandbox/lists'">
				<Button :class="{...listActionButtonClass, 'create-list-button': true, 'green-button__secondary': true}" @click="createList" >
					<span >Create New</span>
					
				</Button>
				
				<Button :class="{...listActionButtonClass, 'edit-list-button': true, 'blue-button': true}" @click="editList" id="editListButton" :disabled="!isEditingList" ref="editListButton">
					<span >Edit</span>
				</Button>
				<Button :class="{...listActionButtonClass, 'delete-list-button': true, 'red-button__secondary': true}" @click="deleteList" id="deleteListButton" :disabled="!isEditingList" ref="deleteListButton">
					<span >Delete</span>
				</Button>
			</template>
			<Button :class="{...listActionButtonClass, 'green-button__secondary': true, 'add-alert-button': true}" @click="createNewAlert" v-else-if="$route.path == '/sandbox/alerts'" >
				<span>Create New Alert</span>
			</Button>
		</template>
	</PillMenuBar>
	<div class="tab-div" v-if="usageAllowed">
		
		<router-view v-slot="{ Component }">

			<keep-alive><component :is="Component"/></keep-alive>

		</router-view>

		<Economy v-show="$route.path == '/sandbox/economy' "/>

		<MarketScreener v-show="$route.path =='/sandbox' || $route.path =='/sandbox/screeners' "/>

		<EarningsCalendar v-show="$route.path =='/sandbox/earningsCalendar' " :hasFeatureEnabled="users?.user?.features.includes('EarningsCalendar')" @access-denied="goBackToSandboxLists"/>

		<DividendsCalendar v-show="$route.path =='/sandbox/dividendsCalendar' " :hasFeatureEnabled="users?.user?.features.includes('DividendsCalendar')" @access-denied="goBackToSandboxLists"/>
			
	</div>
	<InputAlertModal ref='inputAlertModal'  @created-alert="createdAlertCallback"/>
	<UsageLimitModal ref='usageLimitModal' :accessCheck="accessCheck"/>
</template>

<script>
import PillMenuBar from '../components/menu/PillMenuBar';
import { mapState } from 'vuex';
import EventBus from '../event-bus';
import MarketScreener from '../components/QuoteMedia/MarketScreener';
import DividendsCalendar from '../components/sandbox/DividendsCalendar.vue'
import EarningsCalendar from '../components/sandbox/EarningsCalendar';
import Lists from './Lists.vue';
import InputAlertModal from '../components/QuoteMedia/alerts/modal/InputAlertModal.vue';
import Economy from '../components/home/Economy.vue';
import UsageLimitModal  from '../components/modal/UsageLimitModal.vue';
import UserService from '../service/UserService';

export default {
	name: 'Sandbox',
	data() {
		return {
			
			justMounted: false,
			active: false,
			menuItems: [
				{label: "Lists", icon: "pi pi-fw pi-pick-list", to: '/sandbox/lists'},
				{label: "Screeners", icon: "pi pi-fw pi-screener", to: '/sandbox/screeners' },
                {label: "Economy", icon: "pi pi-fw pi-chart-bar", to: '/sandbox/economy'},
				{label: "Earnings Calendar", icon: "pi pi-fw pi-screener", to: '/sandbox/earningsCalendar', className: 'purple-button__secondary', hidden: !this.$store.getters['featureSettings'].premiumSubscriptionEnabled},
				{label: "Dividends Calendar", to: '/sandbox/dividendsCalendar', className: 'purple-button__secondary', hidden: !this.$store.getters['featureSettings'].premiumSubscriptionEnabled},

				//{label: "Charting", icon:"pi pi-fw pi-chart-line", to: '/sandbox/chartTool'},
				{label: "Alerts", to: '/sandbox/alerts', className: 'purple-button__secondary', hidden: !this.$store.getters['featureSettings'].premiumSubscriptionEnabled}
			],
			isEditingList: false,
			usageAllowed: false,
            accessCheck: null,
			

		}
	},
	components: {
		PillMenuBar,Lists, MarketScreener, DividendsCalendar, EarningsCalendar, InputAlertModal, Economy,
		UsageLimitModal
		// Screeners
		
	//	PickLists,//: defineAsyncComponent(() => import('../components/equity/Overview')),
	//	Screeners,//: defineAsyncComponent(() => import('../components/equity/Charting')),
	//	ChartTool,//: defineAsyncComponent(() => import('../components/equity/Documents')),
	},
	created() {
		EventBus.on('list-selected', this.enableEditDeleteListButtons);
		EventBus.on('list-unselected', this.disableEditDeleteListButtons);

		EventBus.on('go-back-to-sandbox', this.goBackToSandboxLists);
	},
	computed: {
    ...mapState(['users']),

		listActionButtonClass() {
			return {
				'p-button-raised': true,
				'p-button-rounded': true,
				active: false,
			}
		},

		
		
	},
	
	mounted() {
		this.justMounted = true;
		console.debug("mounting Sandbox");
	},
	activated() {
		this.active = true;
		console.debug("activated Sandbox");
		// if( !this.justMounted && this.$route.params.selectedSymbol) {
		// 	this.setSelectedSymbolFromQM(this.$route.params.selectedSymbol);
		// }
		this.justMounted = false;
		//this.$router.push('/sandbox/screeners');
		UserService.checkAnalysisToolsUsageRate("tools").then( resp => {
			if( resp.data.status == 'error') {
				this.accessCheck = resp.data.accessCheck;
				this.usageAllowed = false;
				this.$refs.usageLimitModal.open();
				
			}
			else {
				this.usageAllowed = true;
				this.accessCheck = null;
			}
		})
	},
	deactivated() {
		console.debug("deactivated Sandbox");
		this.active = false;
	},
	unmounted() {
		console.debug("unmounted sandbox");
	},
	

	methods: {

		enableEditDeleteListButtons() {
			this.isEditingList = true;
			
		},

		disableEditDeleteListButtons() {
			this.isEditingList = false;
		},

		createList() {
			EventBus.emit("create-list");
		},

		editList() {
			EventBus.emit("edit-list");
		},

		deleteList() {
			EventBus.emit("delete-list");
		},

		createNewAlert() {
			this.$refs.inputAlertModal.open();
		},

		goBackToSandboxLists() {
		this.$router.push({path: '/sandbox/lists'});

		this.menuItems[1].selected = true;
		},

		createdAlertCallback() {
			this.$nextTick(() => {
				EventBus.emit('refresh-alerts');
			});
		}
		
	}
	
}
</script>

<style scoped>
 .pill-menu-bar {
	margin-left: 1rem;
    width: calc(100% - 2rem);
 }

 .tab-div {
	/* background: #1f2d40; */
    padding: 0 1rem 1rem;
    border: 0 none;
    /* color: rgba(255, 255, 255, 0.87); */
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
 }


::v-deep(.pi-pick-list::before) {
        content: "\e967";
}
::v-deep(.pi-pick-list::after) {
        
        content: "\e937";
        font-size: .8rem;
        margin-left: -2px;
        vertical-align:super;
}

::v-deep(.pi-screener::before) {
        content: "\e967";
}
::v-deep(.pi-screener::after) {
        
        content: "\e94c";
        font-size: .8rem;
        margin-left: -3px;
        vertical-align:super;
}
::v-deep(.pi-analyze::before) {
        content: "\e96d";
}
::v-deep(.pi-analyze::after) {
        
        content: "\e908";
        font-size: 1rem;
        margin-left: -3px;
        vertical-align:super;
}
::v-deep(.p-menuitem-link .p-menuitem-icon){
	margin-top: -3px !important;
}

.optional-buttons-container .create-list-button {
	border-width: 2px;
}
.optional-buttons-container .create-list-button.active,
.optional-buttons-container .create-list-button:enabled:hover {
	border-width: 2px;
}

.optional-buttons-container .edit-list-button {
	border-width: 2px;
}
.optional-buttons-container .edit-list-button.active,
.optional-buttons-container .edit-list-button:enabled:hover {
	border-width: 2px;
}
.optional-buttons-container .delete-list-button {
	border-width: 2px;
}
.optional-buttons-container .delete-list-button.active,
.optional-buttons-container .delete-list-button:enabled:hover {
	border-width: 2px;
}
</style>