<template>
    <Dialog v-model:visible="display" :modal="true" :draggable="false" class='create-new-alert-modal' @hide="onHide">
        <template #header>
            <span class='title'>{{headerText}}</span>
        </template>
        <template v-if='!isInEditMode'>
            <Dropdown class="alert-type-dropdown dropdown" v-model="alertTypeOption" :options="alertTypeOptions" :placeholder="alertTypeDropdownPlaceholder"
            optionLabel="title" panelClass="alert-type-dropdown-panel">
                <template #option="slotProps">
                    <div class='alert-type-item-row'>
                        <span>{{slotProps.option.title}}</span>
                        <template v-if='[9, 12].includes(slotProps.option.id)'>
                            <Button icon='pi pi-info' class='alert-type-info-button blue-button' v-tooltip.right="{value: 'Send alert when the security increases/decreases more than the specified change value.'}" v-if="slotProps.option.id === 9"/>
                            <Button icon='pi pi-info' class='alert-type-info-button blue-button' v-tooltip.right="{value: 'Send alert when ask price goes above/below the specified value.'}" v-else/>
                        </template>
                        <Button icon='pi pi-info' class='alert-type-info-button blue-button' v-tooltip.right="{value: slotProps.option.description}" v-else/>
                    </div>
                </template>
            </Dropdown>
            <template v-if="isAlertTypeWithComparators(alertTypeOption.id)">
                <p class='alert-type-description' v-if="comparatorOption">
                    {{comparatorTypeDesc}}
                </p>
            </template>
            <p class='alert-type-description' v-else>
                {{alertTypeOption.description}}
            </p>

            <!-- Security search bar will only appear if we're creating an alert from the actual alerts page and that we have selected an alert type that is not market close related -->
            <span class="p-input-icon-left security-search-bar-container" v-if="!fromSelectingSecurity && (alertTypeOption?.id && !isMarketCloseType(alertTypeOption.id))">
                <AutoComplete ref='securitySearchBar' placeholder="Select a security" forceSelection v-model="selectedSecurity" 
                    :suggestions="securityList" @complete="searchSecurities($event)" field="symbol" optionGroupLabel="label" optionGroupChildren="items"
                    @item-select="onSecuritySelected($event)">
                    <template #item="slotProps">

                        <span class='symbol'>{{slotProps.item.symbol.replace("/", ".")}}</span><br>
                        <small>
                            {{(slotProps.item.company ? slotProps.item.company : slotProps.item.name)}}
                            {{ (slotProps.item.exchangeSymbol ? " - "+securityFormatter.getFormattedExchange(slotProps.item.exchangeSymbol): "")}}
                        </small>
                    </template>
                </AutoComplete>
                <i class="pi pi-search" />
            </span>
        </template>
        <template v-if="isAlertTypeWithNumericInputs(alertTypeOption?.id)">
            <div class='input-row' v-if="isAlertTypeWithComparators(alertTypeOption?.id)">
                <Dropdown class='comparator-dropdown dropdown' v-model="comparatorOption" :options="comparatorOptions" :placeholder="comparatorDropdownPlaceholder"
                panelClass="comparator-dropdown-panel" />
                <span class="p-input-icon-right" v-if="[4,8,9,11,12].includes(alertTypeOption?.id || inputData?.alertType.id)">
                    <i :class="iconClass" />
                    <InputNumber class='user-value-input' v-model='userValue' mode="decimal" :placeholder="userValueInputPlaceholder" :minFractionDigits="2"/>
                </span>
                <InputNumber class='user-value-input' v-model='userValue' mode="decimal" :placeholder="userValueInputPlaceholder" :minFractionDigits="2" v-else/>
            </div>
            <template v-else>
                 <span class="p-input-icon-right" v-if="[4,8,9,11,12].includes(alertTypeOption?.id || inputData?.alertType.id)">
                    <i :class="iconClass" />
                    <InputNumber class='user-value-input' v-model='userValue' mode="decimal" :placeholder="userValueInputPlaceholder" :minFractionDigits="2"/>
                </span>
                <InputNumber class='user-value-input' v-model='userValue' mode="decimal" :placeholder="userValueInputPlaceholder" :minFractionDigits="2" v-else/>
            </template>
        </template>
        <Dropdown class="exchange-group-dropdown dropdown" v-model="exchangeGroup" :options="exchangeGroupOptions" :placeholder="exchangeGroupDropdownPlaceholder"
            panelClass="exchange-group-dropdown-panel" v-if="isMarketCloseType(alertTypeOption?.id)"/>
		<template #footer>
			<Button :label="buttonLabel" class='submit-alert-button green-button__primary' @click="submitAlert" :disabled="disableAddAlertButton"/>
		</template>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';

import {ALERT_TYPES_WITH_NUMERIC_INPUTS, ALERT_TYPES_WITH_COMPARATORS} from '../';
import {ALERT_TYPES_WITH_VALUES, ALERT_PRICE_TYPES, ASSET_TYPES} from '../../../../common/constants';
import AlertsService from '../../../../service/AlertsService';
import ResearchService from '../../../../service/ResearchService';
import SecurityFormatter from '../../../../common/SecurityFormatter';

const MARKET_CLOSE_ALERTS = [31, 32, 33, 34, 35];

export default {
    name: 'InputAlertModal',
    emits: ['updated-alert', 'created-alert'],
    components: {
        AutoComplete, Dialog, Dropdown, InputNumber
    },
    props: {
        inputData: Object //Specifically is used for editing an alert, as we have to supply the alert's data for this modal
    },
    computed: {
        disableAddAlertButton() {
            if (!this.isInEditMode) {
                const option = this.alertTypeOption;
                const isAlertTypeOptionNull = !option.id && !option.title && !option.description;

                
                if (!isAlertTypeOptionNull) {
                    if (this.isAlertTypeWithNumericInputs(option.id)) {
                        if (this.isAlertTypeWithComparators(option.id)) {
                            return !this.comparatorOption || !this.userValue;
                        } else {
                            return !this.userValue
                        }
                    } else if (this.isMarketCloseType(option.id)) {
                        return !this.exchangeGroup;
                    } else {
                        return !this.selectedSecurity?.symbol;
                    }
                } else {
                    return true;
                }
            } else {
                if (this.isAlertTypeWithNumericInputs(this.alertTypeOption.id)) {
                    if (this.isAlertTypeWithComparators(this.alertTypeOption.id)) {
                        const actualComp = this.inputData.comparator === 1 ? '>=' : '<=';
                        // We don't want to update an alert that hasn't changed any of it's values, so we disable it
                        return actualComp === this.comparatorOption && this.inputData.userValue === this.userValue;
                    } else {
                        return this.inputData.userValue === this.userValue;
                    }
                } else {
                    // Same thing here
                    return this.inputData.symbol === this.exchangeGroup;
                }
            }
        },

        buttonLabel() {
            return !this.isInEditMode ? 'Add Alert' : 'Update Alert';
        },

        isInEditMode() {
            // Existing alert objects will have this alert type property, so we can use that to dictate that you're currently editing it
            return this.inputData?.alertType !== undefined;
        },

        fromSelectingSecurity() {
            return this.inputData?.symbol;
        },

        headerText() {
            return this.isInEditMode ? `Edit '${this.inputData.symbol}' Alert` : 'Create New Alert';
        },

        iconClass() {
            const id = this.isInEditMode ? this.inputData?.alertType.id : this.alertTypeOption.id;
            return ALERT_PRICE_TYPES.includes(id) ? 'pi pi-dollar' : 'pi pi-percentage';
        },

        comparatorTypeDesc() {
            const DESCRIPTION_MAP = {
                '<=': {
                    8: 'Send alert when the security drops to or below the specified change value.',
                    9: 'Send alert when the security decreases more than the specified change value.',
                    12: 'Send alert when ask price drops to or below the specified value.'
                },
                '>=': {
                    8: 'Send alert when the security rises to or above the specified change value.',
                    9: 'Send alert when the security increases more than the specified change value.',
                    12: 'Send alert when ask price rises to or above the specified value.'
                }
            }

            return DESCRIPTION_MAP[this.comparatorOption][this.alertTypeOption.id];
        }
    },

    watch: {
        alertTypeOption(val) { //eslint-disable-line

            if (this.isMarketCloseType(val)) {
                this.selectedSecurity.symbol = "";
            }
            this.exchangeGroup = null;
            this.comparatorOption = null;
            this.exchangeGroup = null;
            this.userValue = null;
        }
    },
    data() {
        return {
            //CONSTANTS
            ALERT_TYPES_WITH_VALUES,
            ALERT_TYPES_WITH_NUMERIC_INPUTS,
            MARKET_CLOSE_ALERTS,
            ALERT_TYPES_WITH_COMPARATORS,

            typesToHide: [5, 6, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35],

            securityFormatter: SecurityFormatter,

            display: false,

            exchangeGroupDropdownPlaceholder: 'Select an exchange group',
            exchangeGroupOptions: ['NSD', 'NYE', 'AMX', 'TSX'],
            exchangeGroup: null,

            securitySearchBarPlaceholder: 'Add a security',
            securityList: [],
            selectedSecurity: {
                symbol: "",
                exchangeSymbol: null,
                assetTypeId: null
            },

            alertTypeDropdownPlaceholder: 'Select an alert type',
            alertTypeOption: {
                id: null,
                title: null,
                description: null
            },
            alertTypeOptions: [],

            comparatorOption: null,
            comparatorOptions: ['<=', '>='],
            comparatorDropdownPlaceholder: 'Select a comparator',

            userValue: null,
            userValueInputPlaceholder: 'Enter a value'
        }
    },


    methods: {
        isAlertTypeWithNumericInputs(id) {
            return ALERT_TYPES_WITH_NUMERIC_INPUTS.includes(id);
        },
        isMarketCloseType(id) {
            return MARKET_CLOSE_ALERTS.includes(id);
        },
        isAlertTypeWithComparators(id) {
            return ALERT_TYPES_WITH_COMPARATORS.includes(id);
        },

        open() {
            AlertsService.getAlertTypes().then((resp) => {

                if (this.inputData) {
                    // Existing alert objects will have this alert type property, so we can use that to dictate that you're currently editing it
                    if (!this.isInEditMode) {
                        this.selectedSecurity.symbol = this.inputData?.symbol
                        this.selectedSecurity.exchangeSymbol = this.inputData?.exchangeSymbol;
                        this.selectedSecurity.assetTypeId = this.inputData?.assetTypeId;
                    } else {
                        this.alertTypeOption.id = this.inputData.alertType.id;
                        if (this.isAlertTypeWithNumericInputs(this.alertTypeOption.id)) {
                            this.userValue = this.inputData?.userValue;

                            if (this.isAlertTypeWithComparators(this.alertTypeOption.id)) {
                                this.comparatorOption = this.inputData?.comparator === 1 ? '>=' : '<=';
                            }
                        } else if (this.isMarketCloseType(this.alertTypeOption.id)) {
                            this.exchangeGroup = this.inputData?.symbol;
                        }
                        
                    }
                }

                this.display = true;

                if (resp.data.status === 'success') {

                    let excluded = this.typesToHide;

                    // If we're creating an alert from a security, then we should also filter out the market close alerts as they don't accept security symbols anyway
                    if (this.fromSelectingSecurity) {
                        excluded = excluded.concat(MARKET_CLOSE_ALERTS);
                    }

                    this.alertTypeOptions = resp.data.types.filter((type) => {
                        return !excluded.includes(type.id);
                    });
                }
            });
        },

        onClose() {
            this.display = false;
        },

        onHide() {
            this.selectedSecurity.symbol = "";
            this.selectedSecurity.exchangeSymbol = null;
            this.selectedSecurity.assetTypeId = null;

            this.alertTypeOption = {
                id: null,
                title: null,
                description: null
            };

            this.comparatorOption = null;
            this.userValue = null;
            this.exchangeGroup = null;
        },

        searchSecurities(event){
            ResearchService.queryMarket('All', event.query, 10, 0).then(response => {
				
                let queryResults = response.data;

                this.securityList = [
                {
                    label: 'Equities', code: 'EQ', 
                    items: queryResults.securities
                },
                {
                    label: 'Funds', code: 'F', 
                    items: queryResults.funds
                }];
            })
            .catch(error => {
                this.securityList = []
                
                console.debug('There was an error:', error.response)
            })
        },

        onSecuritySelected(event) {
			this.selectedSecurity.symbol = event.value.symbol;
            this.selectedSecurity.exchangeSymbol = event.value.exchangeSymbol;

            if (event.value.securityId !== undefined) {
                this.selectedSecurity.assetTypeId = ASSET_TYPES['EQUITY'];
            } else if (event.value.fundId !== undefined) {
                this.selectedSecurity.assetTypeId = ASSET_TYPES['FUND'];
            }

            this.$refs.securitySearchBar.$el.blur();
		},

        clearInput(e) {
			e['query'] = '';
        },

        submitAlert() {
            let compVal = null;

            if (this.comparatorOption !== null) {
                compVal = this.comparatorOption === '>=' ? 1 : -1;
            }

            let req = {
                'userId': this.$store.state.users.user.userId,
                'comparator': compVal,
                'userValue': this.userValue
            }

            if (this.isInEditMode) {
                // We can only modify symbols of an alert on our side if we're changing the exchange group of a market close alert
                if (this.isMarketCloseType(this.alertTypeOption.id)) {
                    req['symbol'] = this.exchangeGroup;
                }

                AlertsService.updateAlert(this.inputData.alertId, req).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'Alert successfully updated!', life: 1500, group: 'center' });
                        this.$emit('updated-alert');
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                        console.log("unexpected error updating alert "+resp.data.message);
                    }

                    this.display = false;
                }).catch((error) => {
                        this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                        console.log("unexpected error updating alert "+error);

                        this.display = false;
                })
            } else {


                req['alertTypeId'] = this.alertTypeOption.id;
                // We are sending in symbol for either exchange groups for market close types and security symbols for the rest
                req['symbol'] = !this.isMarketCloseType(this.alertTypeOption.id) ? this.selectedSecurity.symbol : this.exchangeGroup;
                req['assetTypeId'] = this.selectedSecurity.assetTypeId;
                req['exchangeSymbol'] = this.selectedSecurity.exchangeSymbol;
                
                AlertsService.subscribeAlert(req).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.$emit('created-alert');
                        this.$toast.add({ severity: 'success', summary: 'Alert successfully created!', life: 1500, group: 'center' });
                    } else if (resp.data.status === 'error'){
                        this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                        console.log("unexpected error creating alert "+resp.data.message);
                    } else {
                        this.$toast.add({ severity: 'error', summary: resp.data.message, life: 3000, group: 'center' });
                        console.log("unexpected error creating alert "+resp.data.message);
                    }

                    this.display = false;
                }).catch((error) => {
                    this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                    console.log("unexpected error creating alert "+error);

                    this.display = false;
                })
            }
        }
    }
}
</script>

<style>
.create-new-alert-modal {
    width: 430px;
}

.create-new-alert-modal .p-dialog-header {
    padding: 16px;
}

.create-new-alert-modal .p-dialog-content {
    padding: 0px 16px 16px;
    overflow-y: visible;
}

.create-new-alert-modal .p-dialog-footer {
    padding: 0px 0px 16px;
    text-align: center;
}

.exchange-group-dropdown-panel .p-dropdown-items,
.comparator-dropdown-panel .p-dropdown-items,
.alert-type-dropdown-panel .p-dropdown-items {
   padding: 0px; 
}
.exchange-group-dropdown-panel .p-dropdown-items .p-dropdown-item,
.comparator-dropdown-panel .p-dropdown-items .p-dropdown-item,
.alert-type-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 8px 16px;
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.alert-type-description {
    color: black;
    margin-top: 0px;
}

.submit-alert-button {
    border-radius: 50px;
    padding: 8px 64px;
    font-size: 18px;
}

.security-search-bar-container {
    width: 100%;
    margin-bottom: 16px;
}
.p-autocomplete {
    width: 100%;
}
::v-deep(.p-autocomplete-input.p-inputtext.p-component){
	border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    padding: 8px 8px 8px 36px;
    color: #32364e;
    font-size: 16px;
	width: 100%;
}
::v-deep(.p-autocomplete-input.p-inputtext.p-component::placeholder) {
    font-size: 16px;
    color: #BFBFBF;
}
::v-deep(.p-inputtext:enabled:hover) {
	border-color: #33CC99;
}
::v-deep(.p-inputtext:enabled:focus) {
	border-color: #33CC99;
	box-shadow: none;
}

.p-autocomplete.not-clickable {
    pointer-events: none;
}

.input-row {
    display: flex;
}
.input-row > .p-component,
.input-row > .p-input-icon-right {
    width: 50%;
}

.p-input-icon-right,
.user-value-input {
    width: 100%;
}

.p-dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.p-dropdown:hover, .p-dropdown:active {
    border-color: #33CC99;
}
.p-dropdown:focus, .p-dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label) {
    padding: 0px;
    font-size: 1rem;
    color: black;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}
::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}

.alert-type-dropdown {
    margin-bottom: 16px;
}

.alert-type-item-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.alert-type-info-button {
    border-radius: 50px;
    padding: 2px 8px;
    height: 20px;
    width: 20px;
}

.alert-type-item-row > span {
    white-space: normal;
    max-width: 80%;
}

.comparator-dropdown {
    margin-right: 16px;
}

.user-value-input > ::v-deep(.p-inputtext) {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 10px 8px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}
.user-value-input > ::v-deep(.p-inputtext::placeholder) {
    color: #bfbfbf;
}
</style>