import APIService from '@/common/APIService'
import store from '../store/index';

class AlertsServiceImpl {
    getAlerts(userId) {
        return APIService.get('/alerts/subscribed?userId=' + userId); 
    }

    getAlertTypes() {
        return APIService.get('/alerts/types'); 
    }

    subscribeAlert(subscribeReq) {
        return APIService.post('/alerts/subscribe/alert', subscribeReq);
    }

    updateAlert(alertId, updateReq) {
        return APIService.post('/alerts/' + alertId + '/update', updateReq)
    }

    enableAlert(alertId) {
        return APIService.post('/alerts/' + alertId + '/enable', {});
    }

    disableAlert(alertId) {
        return APIService.post('/alerts/' + alertId + '/disable', {});
    }

    deleteAlert(alertId) {
        return APIService.post('/alerts/' + alertId + '?userId=' + store.state.users.user.userId, {} );
    }
}

const AlertsService = new AlertsServiceImpl();

export default AlertsService;