<template>
    <div>
        <LoadingSpinner :showLoading="!chartInitialized" />
        <div class="card" style="position: relative" v-show="chartInitialized">
            <MoreOptionsMenu class="more-options" @click="toggleMenu" />
            <Menu ref="menu" :model="moreMenuItems" :popup="true" />
            <h2 class="dividendscalendar-header">Dividends Calendar</h2>
            <div id="dividendsCalendarVisuals" data-qmod-tool="dividendscalendar" :data-qmod-params="dividendsCalendarParams" class="dividendscalendar-qtool" ref="dividendsCalendarVisuals" />
        </div>

        <template v-if="chartInitialized">
            <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
            <AttributionFooter authors="quoteMedia" />
        </template>
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';
import AttributionFooter from '../footer/AttributionFooter.vue';
import ResearchService from '../../service/ResearchService';

export default {
    name: 'DividendsCalendarVisuals',

    computed: {
        dividendsCalendarParams() {
            return JSON.stringify({
                lang: 'en',
                limit: 200,
                exchangeEnabled: true,
                calendarEnabled: true,
                dateRowEnabled: true,
                datePickerFormat: 'Y-m-d',
                resultsPerPage: 10,
                colVisible: [true, true, true, true, true, true, false, true, true],
                colPriority: [1,4,5,2,6,3,7,8,9]
            });
        },
    },
    components: {
        LoadingSpinner,
        MoreOptionsMenu,
        SnapshotDialog,
        AttributionFooter,
    },
    mounted() {
        console.log(this.$options.name + ' mounted');

        const router = this.$router;
        const toast = this.$toast;

        const onDvidendsCalendarClickListener = (e) => {
            if (e?.target?.offsetParent?.className?.includes('qmod-dividend-symbol')) {
                const symbol = e.target.innerText;

                ResearchService.setSelectedSymbolFromQM(symbol, (resp) => {
                    if (resp == 'success') {
                        router.push({ path: '/equity' });
                    } else {
                        const msg = 'Symbol not found: ' + symbol;
                        toast.add({ severity: 'error', summary: msg, life: 2000, group: 'center' });
                    }
                });
            }
        };

        this.$refs.dividendsCalendarVisuals.addEventListener(
            'qmod-after-template-bind',
            () => {
                console.debug(this.$options.name + ' visuals template bound**************');
                if (!this.chartInitialized) {
                    this.$refs.dividendsCalendarVisuals.addEventListener('click', onDvidendsCalendarClickListener, false);
                }
                this.chartInitialized = true;
                setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 1000);
            },
            false
        ); //should ensure it is fired only once  {once:true}

        this.justMounted = true;
        window.qMod.loadTool('dividendsCalendarVisuals');
    },
    activated() {
        console.debug(this.$options.name + ' activated');
    },
    deactivated() {
        console.debug(this.$options.name + ' deactivated');
        this.justMounted = false;
        this.displaySnapshot = false;
    },

    data() {
        return {
            chartInitialized: false,
            justMounted: false,
            displaySnapshot: false,
            snapshotTarget: null,
            moreMenuItems: [
                {
                    label: 'Take Snapshot',

                    icon: 'pi pi-camera',
                    command: () => {
                        this.openSnapshotDialog();
                    },
                },
            ],
        };
    },

    methods: {
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = this.$refs.dividendsCalendarVisuals.getElementsByClassName('qmod-dividendscalendar')[0];
            this.displaySnapshot = true;
        },
    },
};
</script>

<style>
 /* Global Style */
 /* Calendar control stylings */
.flatpickr-calendar,
.flatpickr-calendar select,
.flatpickr-calendar option,
.flatpickr-calendar input,
.flatpickr-calendar div {
    font-family: 'Trebuchet MS', Verdana !important;
}
.flatpickr-calendar .flatpickr-day.selected {
    background: #32364e !important;
}
.flatpickr-calendar .flatpickr-day.today {
    border-color: #32364e !important;
}
.flatpickr-calendar .flatpickr-day:hover {
    background: #ddd !important;
}
</style>

<style scoped>
.more-options {
    position: absolute;
    right: 16px;
    top: 16px;
}

.dividendscalendar-header {
    font-size: 24px;
    font-weight: 600;
    color: #32364e;
    padding: 0px;
    margin: 0px 0px 16px;
}

::v-deep(h2),
::v-deep(h3),
::v-deep(div),
::v-deep(text),
::v-deep(tspan),
::v-deep(input),
::v-deep(.qmod-ui-tool) {
    font-family: 'Trebuchet MS', Verdana !important;
}

::v-deep(.qmod-dividendscalendar .qmod-heading) {
    display: none;
}

::v-deep(.qmod-dividendscalendar .qmod-lang-en),
::v-deep(.qmod-dividendscalendar .qmod-dividends-output) {
    margin-bottom: 0px;
}

/* Calendar input stylings */
::v-deep(.qmod-dividendscalendar .qmod-calendar-control) {
    width: 100% !important;
    max-width: 315px;
}
::v-deep(.qmod-dividendscalendar .qmod-calendar-control .inner-addon.right-addon input:focus) {
    border: 1px solid #33cc99 !important;
}
::v-deep(.qmod-dividendscalendar .qmod-calendar-control .inner-addon.right-addon input:focus-visible) {
    outline: 1px solid #33cc99 !important;
}
::v-deep(.qmod-dividendscalendar .qmod-calendar-control .inner-addon.right-addon input:hover) {
    outline: 1px solid #33cc99 !important;
}
::v-deep(.qmod-dividendscalendar .qmod-calendar-control .inner-addon i:hover) {
    color: #33cc99 !important;
}

/* Days panel-control stylings */
::v-deep(.qmod-dividendscalendar .qmod-week.qmod-next-week button),
::v-deep(.qmod-dividendscalendar .qmod-week.qmod-previous-week button) {
    background: #ffffff !important;
    color: #32364e;
    border: 1px solid #32364e !important;
}
::v-deep(.qmod-dividendscalendar .qmod-week.qmod-next-week button:hover),
::v-deep(.qmod-dividendscalendar .qmod-week.qmod-previous-week button:hover) {
    background: #32364e !important;
    color: white;
}
::v-deep(.qmod-dividendscalendar .qmod-dividends-date button) {
    background: #ffffff !important;
    border: 1px solid #32364e !important;
}
::v-deep(.qmod-dividendscalendar .qmod-dividends-date button:hover) {
    background: #32364e !important;
    color: white;
}
::v-deep(.qmod-dividendscalendar .qmod-dividends-date .qmod-dividends-date-btn.qmod-btn-active) {
    background: #32364e !important;
    color: white;
}
::v-deep(.qmod-dividendscalendar .qmod-earning-symbol span) {
    color: #33cc99 !important;
    cursor: pointer;
}
::v-deep(.qmod-dividendscalendar .qmod-longname-tooltip) {
    cursor: text;
}

/* Dropdown stylings */
::v-deep(.qmod-dividendscalendar .qmod-modifier) {
    margin: 0px 16px 0px 0px;
}
::v-deep(.qmod-dividendscalendar .qmod-modifier:last-child) {
    margin-right: 0px;
}
::v-deep(.qmod-dividendscalendar .qmod-dropdown-label) {
    color: #7f7f7f;
}
::v-deep(.qmod-dividendscalendar .qmod-dropdown_toggle) {
    color: #222;
    background-color: #fff;
    border: 2px solid #32364e;
    display: inline-block;
    width: 155px;
    height: 30px;
    border-radius: 20px;
}
::v-deep(.qmod-dividendscalendar .qmod-dropdown_toggle:hover) {
    text-decoration: none;
    background-color: #32364e;
    color: #fff;
}
::v-deep(.qmod-dividendscalendar .qmod-dropdown:hover .qmod-dropdown_toggle) {
    background: #fff !important;
    color: #32364e;
}
::v-deep(.qmod-dividendscalendar .qmod-dropdown_toggle) {
    box-sizing: content-box;
}
::v-deep(.qmod-dividendscalendar .qmod-dropdown-menu li > a.qmod-active) {
    background-color: #32364e;
    color: #fff;
}
::v-deep(.qmod-dividendscalendar .qmod-dropdown-menu li > a:hover) {
    background-color: #32364e;
    color: #fff;
}

/* Paginate button stylings */
::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate .paginate_button:hover),
::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate .paginate_button.current) {
    background: linear-gradient(#32364e, #32364e) !important;
    color: #fff !important;
    border: 1px solid #32364e !important;
    box-shadow: none !important;
}
::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate .paginate_button) {
    background-color: #fff !important;
    color: #32364e !important;
    border: 1px solid #32364e !important;
    font: 14px Trebuchet MS, Verdana !important;
    border-radius: 25px !important;
}
::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate .paginate_button:hover),
::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover),
::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover) {
    background-color: #32364e !important;
    color: #fff !important;
    border: 1px solid #32364e !important;
}

::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate .paginate_button.next),
::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate .paginate_button.previous) {
    font-family: 'quotemedia' !important;
}

::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_info) {
    display: none;
}
::v-deep(.qmod-dividendscalendar .dataTables_wrapper .dataTables_paginate) {
    float: none;
    text-align: center;
    padding-top: 1.25rem;
    display:block;
}

::v-deep(.qmod-ui-tool),
::v-deep(.pure-g [class*="pure-u"]) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}

::v-deep(td.qmod-control.qmod-td-0.control) {
    display:none;
}

::v-deep(td.qmod-control.qmod-th-0.control.sorting_disabled){
    display:none;
}
</style>