<template>
    <Dialog v-model:visible="display" :modal="true" class='upgrade-to-premium-modal' @hide="onHide" id="usageLimitModal">
        <template #header>
           <span class='title'>Usage Limit Reached.</span>
        </template>
        <div class="body-text">
             You've reached your {{usageText}} limit.<br> <small>({{limitText}})</small><br><br>
            Get a <strong>Premium Subscription</strong> for unlimited access.<br>
            Or<br>
            Buy an <strong>Investor Pass</strong> using your <strong>Playdough</strong> to get temporary unlimited access. <br>
            <span v-if='usageText.indexOf("Trading") > 0' style="font-size:12px">
                (Note that Competition passes only remove trading usage limits during the competition and only when using the competition investment account.)</span>
        </div>
       

		<template #footer>
			
            <Button :label="upgradeButtonLabel" class='green-button__primary p-button-raised p-button-rounded upgrade-button' @click="goToPricingPage"/>
		</template>
	</Dialog>
</template>

<script>



import Dialog from 'primevue/dialog';


const ANALYSIS_TOOLS = 49;

export default {
    name: 'UsuageLimitModal',
    emits: ['cancel-upgrade'],
    components: {
        Dialog
    },

    props: {
        accessCheck: {
            type: Object,
            required: false
        },
      


        
    },
    data() {
		return {
           
			display: false,
            goToBilling: false,
		}
    },

    computed: {
    

        upgradeButtonLabel() {
            return "View Subscriptions and Passes"
           
        },

        usageText() {
            if( this.accessCheck.config.featureId == ANALYSIS_TOOLS ) {
                return ' Research usage';
            }
            else {
                return 'Daily Trading';
            }
        },

        period() {
            if( this.accessCheck.config.numDays > 1 ) {
                return this.accessCheck.config.numDays + ' day';
            }
            else {
                return 'daily';
            }

        },

        limitText() {
            if( this.accessCheck.config.numDays > 1 ) {
                return this.accessCheck.config.maxUsage +" research sessions within a "+ this.accessCheck.config.numDays +" day period.";
            }else {
                return this.accessCheck.config.maxUsage +" per day."
            }
        }

        

        
    },

    methods: {
        open() {
            this.display = true;
        },

        close() {
            this.display = false;
        },

        onHide() {
            if (this.goToBilling) {
                return;
            } else {
                this.$emit('cancel-upgrade');
            }
        },


        goToPricingPage() {
            // go to pricing page before checkout
            this.goToBilling = true;
            this.close();
            this.$router.push('/billing');
        },
    }
}
</script>

<style>
#usageLimitModal strong {
    color:#33CC99;
}
.upgrade-to-premium-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 550px;
}

.upgrade-to-premium-modal .p-dialog-header {
    padding: 0px 8px 0px 0px;
}

.upgrade-to-premium-modal .p-dialog-content {
    padding: 0px 18px 0px;
}

.upgrade-to-premium-modal .p-dialog-footer {
    padding: 34px 0px;
    text-align: center;
}

@media (max-width: 760px) {
    .upgrade-to-premium-modal {
        width: 350px;
    }
    .upgrade-to-premium-modal .p-dialog-content {
         padding: 0px 3px 0px;
    }

    #usageLimitModal .upgrade-button {
        font-size: 16px;
        padding: 8px 49px;
    }
}

</style>
<style scoped>
 *:not(.pi), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
} 

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    padding: 16px 0px 16px 16px;
    text-align: center;
}

.header-highlight {
    color: #693BF5;
}

.body-text {
    text-align: center;
    font-size: 18px;
    color: #32364e;
    margin: 12px 0px 30px;
}

.perks-container {
    text-align: center;
}

.perk-container {
    margin-bottom: 16px;
}
.perk-container i {
    color: #33CC99;
}
.perk-container span {
    color: black;
    font-size: 18px;
}
.perk-container:last-child {
    margin-bottom: 0px;
}

.upgrade-button, .view-button {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 64px;
    margin: 0px;
}

.view-button {
    background-color: #fff;
    color: #32364e;
    border: 1px solid #32364e;
    font-weight: normal;
}

.view-button:hover {
    background-color: #32364e;
    border: 1px solid #32364e;
}

.p-button:last-child {
    margin-right: 0 !important;
}
</style>