<template>
    <div>
        <div class="indices-container" v-if="!isMobile()">
                <div class="card" v-for="(quote, i) of indicesQuotes" :key="i" @click="onIndicesCardClicked(quote.qmSymbol)">
                    <MiniQuotesSimple :name="quote.name" :change="quote.priceData.change" :changepercent="quote.priceData.changepercent" />
                </div>
        </div>
        <div>
            <div class="main-container">
                <div class="p-grid">
                    <div class="left-col p-col-12 p-md-6">
                        <div class="card">
                            <!-- <keep-alive> -->
                                <MiniMarketCurrencies />
                            <!-- </keep-alive> -->
                        </div>
                    </div>
                    <div class="right-col p-col-12 p-md-6">
                        <div class="card">
                            <!-- <keep-alive> -->
                                <MiniMarketIndices />
                            <!-- </keep-alive> -->
                        </div>
                    </div>
                </div>
                <div class="p-grid" v-if="this.featureSettings.premiumSubscriptionEnabled && !isMobile()">
                    <div class="left-col p-col-12 p-md-6">
                        <div class="card">
                            <!-- <keep-alive> -->
                                <MiniMarketFutures :hasFeatureEnabled="$store.getters['users/featureList'].includes('MiniMarketFutures')" @open-upgrade-to-premium-modal="openUpgradeToPremiumModal" />
                            <!-- </keep-alive> -->
                        </div>
                    </div>
                    <div class="right-col p-col-12 p-md-6">
                        <div class="card">
                            <!-- <keep-alive> -->
                                <MiniMarketRates :hasFeatureEnabled="$store.getters['users/featureList'].includes('MiniMarketRates')" @open-upgrade-to-premium-modal="openUpgradeToPremiumModal" />
                            <!-- </keep-alive> -->
                        </div>
                    </div>
                </div>
                <AttributionFooter authors="quoteMedia" />
            </div>
            <UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="upgradeToPremiumModalBodyText" />
        </div>
    </div>
</template>

<script>
import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';
import { mapState } from 'vuex';

import MiniMarketCurrencies from '../QuoteMedia/MiniMarketCurrencies';
import MiniMarketFutures from '../QuoteMedia/MiniMarketFutures';
import MiniMarketIndices from '../QuoteMedia/MiniMarketIndices';
import MiniMarketRates from '../QuoteMedia/MiniMarketRates';
import MiniQuotesSimple from '../QuoteMedia/MiniQuotesSimple';

import AttributionFooter from '../footer/AttributionFooter.vue';
import UpgradeToPremiumModal from '../modal/UpgradeToPremiumModal';
import DataServices from '@/service/DataService';
import ResearchService from '../../service/ResearchService';
import BrowserUtils from '../../utilities/BrowserUtils';

import UserUtils from '../../utilities/UserUtils';

export default {
    name: 'Economy',
    data() {
        return {
            //CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

            justMounted: false,
            active: false,
            upgradeToPremiumModalBodyText: 'To get full access to market data and the other premium features below, upgrade to premium.',

            indicesQuotes: [
                { symbol: 'SPY', qmSymbol: 'SPY', name: 'S&P 500' },
                { symbol: 'QQQ', qmSymbol: 'QQQ', name: 'Nasdaq' },
                { symbol: 'DIA', qmSymbol: 'DIA', name: 'Dow' },
                { symbol: 'XIC', qmSymbol: 'XIC:CA', name: 'TSX' },
            ],
            indicesParam: 'SPY,QQQ,DIA,XIC:CA',
            indicesLoader: null,
        };
    },

    components: {
        MiniMarketCurrencies,
        MiniMarketFutures,
        MiniMarketIndices,
        MiniMarketRates,
        AttributionFooter,
        UpgradeToPremiumModal,
        MiniQuotesSimple,
    },

    computed: {
        ...mapState(['users']),
        ...mapState(['featureSettings']),
    },

    watch: {
        $route(newRoute, oldRoute) { //eslint-disable-line
            if ( newRoute.fullPath == '/sandbox/economy') {
                //console.log(this.$options.name + ' route watcher');
               // if (!this.justMounted && this.active) { // remove this after testing broken image
                //console.log(newRoute.fullPath+" triggering economy loadTools()")
                    this.loadTools('route change');
                    this.startPollingIndices();
               // }
            }
            else {
                this.stopPollingIndices();
            }
        },
    },

    created() {
        this.indicesQuotes = this.indicesQuotes.map(this.initializeIndices);
    },

    mounted() {
        console.log('*********************economy mounted ********************');
        this.justMounted = true;
        if( this.isMobile() ){
            this.loadTools('mounted');
        }
    },

    activated() {
        this.active = true;
        console.log('********************* economy activated *********************');
        if (!this.justMounted) {
           this.loadTools('activated');
        }
        this.justMounted = false;

        this.startPollingIndices();
    },

    deactivated() {
        console.log('economy deactivated');
        this.justMounted = false;
        this.active = false;

        this.stopPollingIndices();
    },

    unmounted() {
        this.stopPollingIndices();
    },

    methods: {
        openUpgradeToPremiumModal() {
            this.$refs.upgradeToPremiumModal.open();
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        loadTools(ctx) {
            
            this.$nextTick( () => {
                console.log("load tools called from "+ ctx);
                window.qMod.loadTool('miniMarketCurrencies');
               
                window.qMod.loadTool('miniMarketIndices');

                if( UserUtils.isPremiumSubscriber() ){
                    window.qMod.loadTool('miniMarketFutures');
                    window.qMod.loadTool('miniMarketRates');
                }
            })
           
        },

        onIndicesCardClicked(symbol) {
            ResearchService.setSelectedFundSymbolFromQM(symbol, () => {
                this.$router.push({ path: '/fund' });
            })
        },

        initializeIndices(quote) {
            return {
                symbol: quote.symbol,
                qmSymbol: quote.qmSymbol,
                name: quote.name,
                priceData: {
                    change: 0.0,
                    changepercent: 0.0,
                },
            };
        },

        startPollingIndices() {
             console.log("starting economy polling");
            if (this.indicesLoader) {
                clearInterval(this.indicesLoader);
            }
            this.loadIndicesData();
            this.indicesLoader = setInterval(() => {
                this.loadIndicesData();
            }, 5000);
        },

        stopPollingIndices() {
            console.log("stopping economy polling");
            if (this.indicesLoader) {
                clearInterval(this.indicesLoader);
            }
        },

        async loadIndicesData() {
            //console.log("loadIndicesData for Economy called");
            if (this.$store.state.registered && !this.isMobile()) {
                await DataServices.getSnapQuotes(this.indicesParam).then(resp => {
                    let quoteData = resp.data.results.quote;
                    //console.log("getSnapQuotes2",quoteData);
                    if (quoteData) {
                        this.indicesQuotes.forEach(quote => {
                            quoteData.forEach(q => {
                                if (q.key.symbol == quote.qmSymbol && q.pricedata) {
                                    quote.priceData.change = q.pricedata.change;
                                    quote.priceData.changepercent = q.pricedata.changepercent;
                                }
                            });
                        });
                    }
                });
            } else {
                console.log('economy not calling getSnapQuotes yet, user is not registered or using mobile');
            }
        },
    },
};
</script>
<style scoped>
::v-deep(h2),
::v-deep(h3),
::v-deep(div),
::v-deep(text),
::v-deep(th),
::v-deep(button),
::v-deep(.qmod-ui-tool) {
    font-family: 'Trebuchet MS', Verdana !important;
}

::v-deep(.p-grid) {
    margin: 0px;
}

::v-deep(.p-grid .left-col) {
    padding: 0px 12px 0px 0px;
}
::v-deep(.p-grid .right-col) {
    padding: 0px 0px 0px 12px;
}
@media screen and (max-width: 768px) {
    ::v-deep(.p-grid .left-col),
    ::v-deep(.p-grid .right-col) {
        padding: 0 !important;
    }
}

::v-deep(.card) {
    margin-bottom: 16px;
    padding: 16px;
}

::v-deep(.more-options) {
    position: absolute;
    right: 0px;
    top: 0px;
    display: none;
}

::v-deep(.heading-title) {
    font-size: 24px !important;
    color: #32364e !important;
    font-weight: bold !important;
}

/* Indices */
.indices-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    align-items: center;
}
.indices-container .card {
    flex: 1;
    border-radius: 1rem;
    cursor: pointer;
}
/* .indices-container-sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
} */
/* .indices-container-sm .card {
    border-radius: 0;
    flex: 48%;
    margin: 1%;
    zoom: 75%;
} */
::v-deep(.min-quote .qmod-quote .display-name),
::v-deep(.min-quote .qmod-quote .qmod-change-group) {
    font-size: 1.286rem;
}
.indices-container .card:last-child {
    margin-right: 0px;
}

/* OpenUpgradeToPremiumModal button */
::v-deep(.open-upgrade-to-premium-modal-btn) {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
    width: 50%;
    display: inline-block;
    border-color: #693bf5;
    background: #693bf5;
    color: white;
    border-radius: 50px;
    border: 2px solid #693bf5;
    font-weight: bold !important;
    font-size: 16px !important;
}
::v-deep(.open-upgrade-to-premium-modal-btn:hover) {
    border: 2px solid #693bf5;
    background: #5831cf;
    color: white;
    border-radius: 50px;
}
::v-deep(.open-upgrade-to-premium-modal-btn:active) {
    border: 2px solid #693bf5;
    background: #4b2ab1;
    color: white;
    border-radius: 50px;
}

/* qmod-block-wrapper remove margin bottom */
::v-deep(.qmod-block-wrapper) {
    margin-bottom: 0px !important;
}

/* Data date */
::v-deep(.qmod-mrktime) {
    margin-top: 10px !important;
}

/* Time period button */
::v-deep(.qmod-button-group li .qmod-btn:hover),
::v-deep(.qmod-button-group li .qmod-btn.qmod-btn-active) {
    background: #32364e;
    color: white;
    border: 1px solid #32364e;
}
::v-deep(.qmod-button-group li .qmod-btn) {
    background: white;
    color: #32364e;
    border: 1px solid #32364e;
}

/* Tab item button list */
::v-deep(.qmod-tab-container .qmod-tab-list) {
    margin-bottom: 10px !important;
}
/* Tab item button */
::v-deep(.qmod-tab-container .qmod-tab-list .qmod-tab-item) {
    background: white;
    background-color: white;
    color: #32364e !important;
    border: 1px solid #32364e !important;
    border-radius: 15px !important;
}
::v-deep(.qmod-tab-container .qmod-tab-list .qmod-tab-item:hover),
::v-deep(.qmod-tab-container .qmod-tab-list .qmod-tab-item.qmod-active) {
    background: #32364e !important;
    background-color: #32364e !important;
    color: white !important;
    border: 1px solid #32364e !important;
    border-radius: 15px !important;
}
::v-deep(.qmod-tab-container .qmod-tab-list .qmod-tab-item a .qmod-tab-text) {
    padding: 4px 8px !important;
    min-width: 100% !important;
    font-weight: normal !important;
}

/* Selected table row */
::v-deep(.qmod-table-row.qmod-tr-active) {
    background-color: #32364e !important;
    background: #32364e !important;
    color: white !important;
}

/* minimarketrates table */
::v-deep(.qmod-minimarketrates .qmod-minimarketrates-table-con) {
    overflow: visible;
    transition: unset;
    height: auto !important;
}
::v-deep(.qmod-minimarketrates .qmod-table-row.qmod-tr-active .qmod_norealtime) {
    color: white !important;
}

/* Hided minimarketfutures indices tab */
/* ::v-deep(.qmod-minimarketfutures li.qmod-tab-item:nth-child(5)) {
    display: none;
} */

/* Hided read-more button */
::v-deep(.qmod-read-more) {
    display: none !important;
}

/* Chart image */
::v-deep(.qmod-chart-plot) {
    height: 175px !important;
    width: 100% !important;
    overflow: hidden;
    text-align: center;
}

/* Scrollable table wrapper */
::v-deep(.scrollable-table-wrap) {
    overflow: auto;
    overflow-y: scroll;
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}
::v-deep(.scrollable-table-wrap::-webkit-scrollbar) {
    width: 10px;
}
::v-deep(.scrollable-table-wrap::-webkit-scrollbar-track) {
    background-color: #f2f2f2;
    opacity: 1;
    transition: background-color 0.2s;
}
::v-deep(.scrollable-table-wrap::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
    background: #bfbfbf; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
}
/* Table changes related to scrollable table wrapper */
::v-deep(.qmod-minimarketfutures-table-con) {
    overflow: unset !important;
}
::v-deep(thead tr th) {
    position: sticky !important;
    top: 0 !important;
    background: white !important;
}
::v-deep(tbody tr td) {
    overflow: hidden !important;
    width: 100%;
}

/* Main qmod div */
::v-deep(div.qmod-block-wrapper div.pure-g div.pure-u-1-1) {
    display: flex !important;
    flex-direction: column !important;
}
/* Main qmod div and loader-container should match height */
::v-deep(div.qmod-block-wrapper div.pure-g div.pure-u-1-1),
::v-deep(.card .loader-container) {
    height: 475px !important;
}

/* Footer */
.attribution-footer {
    margin-top: 40px;
    margin-bottom: 20px;
}
</style>